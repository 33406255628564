import React, { useState, useRef, useEffect } from "react";
import { useMsal } from "@azure/msal-react";

import ListGroup from "react-bootstrap/ListGroup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { TodoForm } from "./TodoForm";
import { TodoItem } from "./TodoItem";

import { blockTrial, extendTrial, getTask, validateTrial, getSignupDetails, addPipeDeal, markTrial, addPipeLead, checkPerson, addPersonToPipe, updateLeadFields } from '../fetch';



function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

export const ListView = (props) => {
    const [tasks, setTasks] = useState(props.todoListData);
    const [name, setName] = useState('');

    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });

        const sortedTasks = [...tasks].sort((a, b) => {
            const aValue = a[key];
            const bValue = b[key];

            // Always move blank, undefined, or null values to the bottom
            if (aValue === null || aValue === undefined || aValue === '') return 1; // Always treat aValue as larger when blank
            if (bValue === null || bValue === undefined || bValue === '') return -1; // Always treat bValue as larger when blank

            // Normal sorting logic for non-blank values
            if (aValue < bValue) return direction === 'asc' ? -1 : 1;
            if (aValue > bValue) return direction === 'asc' ? 1 : -1;
            return 0;
        });

        setTasks(sortedTasks);
    };

    const tableContainerRef = useRef(null);

    // Function to scroll left or right
    const scrollTable = (direction) => {
        const scrollAmount = 300; // Amount to scroll
        if (direction === "right") {
            tableContainerRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
        } else {
            tableContainerRef.current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
        }
    };

    const [minExpirationDate, setMinExpirationDate] = useState(null);

    // Set an initial value for selectedDate
    const initialSelectedDate = minExpirationDate ? new Date(minExpirationDate) : new Date();
    initialSelectedDate.setDate(initialSelectedDate.getDate() + 7);
    const [selectedDate, setSelectedDate] = useState(initialSelectedDate);
    const [actualexpDate, setactualexpDate] = useState(initialSelectedDate);

    const handleAddTask = (name) => {
        setName(name);
        if (name) {
            const filtered = props.todoListData.filter(dataT => dataT.client_url
                .toString()
                .toLowerCase()
                .includes(name.toLowerCase()));
            setTasks(filtered);
        }
        if (!name) {
            setTasks(props.todoListData);
        }

    }

    const filterInvalidAccounts = () => {

        var isChecked = document.getElementById("cb1");
        var isChecked2 = document.getElementById("cb2");
        var isChecked3 = document.getElementById("cb3");

        var removeFilterButton = document.getElementById("cb4");

        if (isChecked.checked) {
            // console.log("Here");
            const filtered = props.todoListData.filter(dataT => dataT.pending_verification === 1);
            setTasks(filtered);
            isChecked2.checked = false;
            isChecked3.checked = false;
            removeFilterButton.checked = false;
        }
        else {
            isChecked2.checked = false;
            isChecked3.checked = false;
            removeFilterButton.checked = true;
            setTasks(props.todoListData);
        }
    }

    const filterActiveAccounts = () => {

        var isChecked = document.getElementById("cb2");
        var isChecked2 = document.getElementById("cb1");
        var isChecked3 = document.getElementById("cb3");
        var removeFilterButton = document.getElementById("cb4");

        if (isChecked.checked) {
            const filtered = props.todoListData.filter(dataT => dataT.pending_verification === 0 && dataT.pending_activation === false);
            setTasks(filtered);
            isChecked2.checked = false;
            isChecked3.checked = false;
            removeFilterButton.checked = false;
        }
        else {
            isChecked2.checked = false;
            isChecked3.checked = false;
            removeFilterButton.checked = true;
            setTasks(props.todoListData);
        }

    }

    const filterValidInactiveAccounts = () => {

        var isChecked = document.getElementById("cb3");
        var isChecked2 = document.getElementById("cb1");
        var isChecked3 = document.getElementById("cb2");
        var removeFilterButton = document.getElementById("cb4");

        if (isChecked.checked) {
            const filtered = props.todoListData.filter(dataT => dataT.pending_verification === 0 && dataT.pending_activation === true);
            setTasks(filtered);
            isChecked2.checked = false;
            isChecked3.checked = false;
            removeFilterButton.checked = false;
        }
        else {
            isChecked2.checked = false;
            isChecked3.checked = false;
            removeFilterButton.checked = true;
            setTasks(props.todoListData);
        }

    }

    const removeFilters = () => {

        var isChecked = document.getElementById("cb4");
        var isChecked1 = document.getElementById("cb3");
        var isChecked2 = document.getElementById("cb1");
        var isChecked3 = document.getElementById("cb2");
        if (isChecked.checked) {
            setTasks(props.todoListData);
            isChecked2.checked = false;
            isChecked1.checked = false;
            isChecked3.checked = false;
        }
        else {
            setTasks(props.todoListData);
            isChecked2.checked = false;
            isChecked1.checked = false;
            isChecked3.checked = false;
        }
    }



    const handleValidateTask = (id, contact, accountUrl, contactName) => {
        //console.log(id);
        var overlayme = document.getElementById("dialog-container");
        var validateDialog = document.getElementById("confirmValidate");
        validateDialog.innerHTML += contactName + " | " + contact + " <br /> <br /> Are you sure you want to validate <a  href=" + accountUrl + ">" + accountUrl + "</a> ?";
        overlayme.style.display = "block";
        var ValidateConfirm = document.getElementById("confirmValidate");
        ValidateConfirm.style.display = "block";
        document.getElementById("confirm").onclick = function () { validateAction(overlayme, id) };
        document.getElementById("cancel").onclick = function () { cancel(overlayme) };


    }

    function validateAction(overlayme, id) {
        var confirmButton = document.getElementById("confirm");
        var cancelButton = document.getElementById("cancel");
        confirmButton.style.display = "none";
        cancelButton.style.display = "none";
        validateTrial(id).then((response) => {

            if ((response.status === 200 || response.status === 201) && response.data.product_package_id != 0) {
                var vM = document.getElementById("vMess");
                vM.style.display = "block";
                var ValidateConfirm = document.getElementById("confirmValidate");
                ValidateConfirm.innerHTML = "";
                overlayme.style.display = "none";
                toast.success('Validation Successful. Page will Reload', {
                    onClose: () => {
                        location.reload();
                    },
                    autoClose: 3000
                });
            }
            else {
                var vM = document.getElementById("vFailMess");
                vM.style.display = "block";
                var ValidateConfirm = document.getElementById("confirmValidate");
                ValidateConfirm.innerHTML = "";
                vM.style.display = "none";
                overlayme.style.display = "none";
                toast.error('Validation was NOT successful. Page will Reload', {
                    onClose: () => {
                        location.reload();
                    },
                    autoClose: 3000
                });
            }
        });
    }

    const handleMarkTrial = (id, contact, accountUrl, contactName, phone, packageName) => {
        //console.log(id);
        var overlayme = document.getElementById("dialog-container");
        overlayme.style.display = "block";
        var markConfirm = document.getElementById("confirmMark");
        markConfirm.style.display = "block";
        document.getElementById("confirm").onclick = function () { markAction(overlayme, id, contact, contactName, phone, packageName) };
        document.getElementById("cancel").onclick = function () { cancel(overlayme) };

    }

    function markAction(overlayme, id, contact, contactName, phone, packageName) {
        var confirmButton = document.getElementById("confirm");
        var cancelButton = document.getElementById("cancel");
        confirmButton.style.display = "none";
        cancelButton.style.display = "none";
        let pId;
        markTrial(id).then((response) => {
            //console.log(response.status);
            //console.log(response.data.product_package_id);
            if ((response.status === 200 || response.status === 201) && response.data[0].product_package_id !== 0) {
                const clientUrl = response.data[0].clienturl;
                const package_id = response.data[0].product_package_id;

                checkPerson(contact, contactName, phone).then((response) => {
                    //console.log(response.data.data.items.length);
                    //console.log(response.data.data.items[0].item.id);
                    if (response.data.data.items.length === 0) {
                        addPersonToPipe(contact, contactName, phone).then((response) => {
                            //console.log(response.data.data.id);
                            pId = response.data.data.id;
                            addLeadInPipe(clientUrl, package_id, pId, packageName);

                        });
                    }
                    else {
                        pId = response.data.data.items[0].item.id;
                        addLeadInPipe(clientUrl, package_id, pId, packageName);

                    }

                });



                var vM = document.getElementById("mMess");
                vM.style.display = "block";
                var ValidateConfirm = document.getElementById("confirmMark");
                ValidateConfirm.innerHTML = "";
                overlayme.style.display = "none";
                toast.success(`Retrieved Signup "${clientUrl}" and will be added as Lead. Page will Reload`, {
                    onClose: () => {
                        location.reload();
                    },
                    autoClose: 3000
                });
            }
            else {
                var vM = document.getElementById("mFailMess");
                vM.style.display = "block";
                var ValidateConfirm = document.getElementById("confirmMark");
                ValidateConfirm.innerHTML = "";
                vM.style.display = "none";
                overlayme.style.display = "none";
                toast.error(`Retrieving Signup "${clientUrl}", Adding as Lead was NOT successful. Page will Reload`, {
                    onClose: () => {
                        location.reload();
                    },
                    autoClose: 3000
                });
            }
        });
    }

    const addLeadInPipe = (clientUrl, package_id, pId, packageName) => {
        // console.log(pId);
        addPipeLead(clientUrl, package_id, pId).then((response) => {
            console.log(response.status);
            if (response.status === 200 || response.status === 201) {
                const leadId = response.data.data.id;
                leadFieldsUpdate(leadId, packageName, clientUrl);
                toast.success(`Lead "${clientUrl}" added in Pipedrive`, {
                    onClose: () => {
                        location.reload();
                    },
                    autoClose: 3000
                });

            }
            else {
                toast.error(`There was a problem adding Lead "${clientUrl}" in Pipedrive`, {
                    onClose: () => {
                        location.reload();
                    },
                    autoClose: 3000
                });
            }
        });


    }

    const leadFieldsUpdate = (leadId, packageName, clientUrl) => {
        updateLeadFields(leadId, packageName, clientUrl).then((response) => {
            if (response.status === 200 || response.status === 201) {
                toast.success(`Lead "${clientUrl}" fields updated in Pipedrive`, {
                    onClose: () => {
                        location.reload();
                    },
                    autoClose: 3000
                });
            }
            else {
                toast.error(`There was a problem updating Lead "${clientUrl}" fields in Pipedrive`, {
                    onClose: () => {
                        location.reload();
                    },
                    autoClose: 3000
                });
            }
        });
    }

    const handleExtendTrial = (id, expiration_date, expirationDate) => {
        var overlayme = document.getElementById("dialog-container");
        overlayme.style.display = "block";
        var extendDialog = document.getElementById("confirmExtend");
        extendDialog.innerHTML += "Are you sure you want to extend trial ? (Expires " + formatDate(expirationDate) + ")";
        var extendConfirm = document.getElementById("confirmExtend");
        extendConfirm.style.display = "block";
        var extendDate = document.getElementById("extensionDate");
        extendDate.style.display = "block";

        const initialSelectedDate = new Date(minExpirationDate);
        initialSelectedDate.setDate(initialSelectedDate.getDate() + 7);
        const newDate = new Date(expirationDate);
        newDate.setDate(newDate.getDate() + 7);
        setSelectedDate(newDate)
        setactualexpDate(expirationDate)
        document.getElementById("confirm").onclick = function () {
            const datePickerInput = document.querySelector('.extendDate input');
            if (datePickerInput.value) {
                extendAction(overlayme, id, datePickerInput.value);
            } else {
                datePickerInput.click();

            }
        };
        document.getElementById("cancel").onclick = function () { cancel(overlayme) };

    }


    function formatDate(selDate) {
        const exp_createDate_Obj = new Date(selDate);
        let dateFormatted;
        const weekday = new Array("Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat");
        let day = weekday[exp_createDate_Obj.getDay()];
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let month = months[exp_createDate_Obj.getMonth()];
        let hours = exp_createDate_Obj.getHours();
        const ampmSwitch = (hours > 12) ? "PM" : "AM";
        if (hours > 12) {
            hours -= 12;

        }
        else if (hours === 0) {
            hours = 12;
        }

        dateFormatted = month + " " + exp_createDate_Obj.getDate() + "," + exp_createDate_Obj.getFullYear() + " at " + exp_createDate_Obj.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
        return dateFormatted;
    }


    function changeDateFormatAndIncrementDay(dateVal) {
        // Split the date string into parts: month, day, and year
        const parts = dateVal.split('/');

        // Create a Date object with the original date
        const originalDate = new Date(`${parts[2]}-${parts[0]}-${parts[1]}`);

        // Increment the day by 1
        originalDate.setDate(originalDate.getDate() + 1);

        // Get the year, month, and day components of the updated date
        const year = originalDate.getFullYear();
        const month = originalDate.getMonth() + 1; // Months are 0-based
        const day = originalDate.getDate() + 1;

        // Ensure two-digit formatting for month and day
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;

        // Create the final date string in 'yyyy-mm-dd' format
        const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;

        return formattedDate;
    }

    function extendAction(overlayme, id, dateVal) {
        /* code executed if confirm is clicked */
        var confirmButton = document.getElementById("confirm");
        var cancelButton = document.getElementById("cancel");
        confirmButton.style.display = "none";
        cancelButton.style.display = "none";

        const formattedDate = changeDateFormatAndIncrementDay(dateVal);

        extendTrial(id, formattedDate).then((response) => {
            if ((response.status === 200 || response.status === 201) && (response.data[0].product_package_id !== 0)) {
                //console.log("Trial Extended for 7 days");

                var eM = document.getElementById("eMess");
                eM.style.display = "block";
                var extendConfirm = document.getElementById("confirmExtend");
                extendConfirm.style.display = "none";
                var extensionDate = document.getElementById("extensionDate");
                extensionDate.style.display = "none";
                eM.style.display = "none";
                overlayme.style.display = "none";
                toast.success('Trial was Extended. Page will Reload', {
                    onClose: () => {
                        location.reload();
                    },
                    autoClose: 3000
                });
            }
            else {
                var eM = document.getElementById("eFailMess");
                eM.style.display = "block";
                var extendConfirm = document.getElementById("confirmExtend");
                extendConfirm.style.display = "none";
                var extensionDate = document.getElementById("extensionDate");
                extensionDate.style.display = "none";
                eM.style.display = "none";
                overlayme.style.display = "none";
                toast.error('Trial was NOT extended. Page will Reload', {
                    onClose: () => {
                        location.reload();
                    },
                    autoClose: 3000
                });
            }
        });


    }



    const handleBlockTrial = (id, contact, accountUrl, contactName) => {
        //console.log(id);
        var overlayme = document.getElementById("dialog-container");
        overlayme.style.display = "block";
        var blockDialog = document.getElementById("confirmBlock");
        blockDialog.innerHTML += contactName + " | " + contact + " <br /> <br /> Are you sure you want to block <a  href=" + accountUrl + ">" + accountUrl + "</a> ?";
        var blockConfirm = document.getElementById("confirmBlock");
        blockConfirm.style.display = "block";
        document.getElementById("confirm").onclick = function () { blockAction(overlayme, id) };
        document.getElementById("cancel").onclick = function () { cancel(overlayme) };
    }

    function blockAction(overlayme, id) {

        var confirmButton = document.getElementById("confirm");
        var cancelButton = document.getElementById("cancel");
        confirmButton.style.display = "none";
        cancelButton.style.display = "none";
        blockTrial(id).then((response) => {
            console.log(response);
            if ((response.status === 200 || response.status === 201) && response.data.product_package_id != 0) {
                //alert("Sign-up Blocked");
                var bM = document.getElementById("bMess");
                bM.style.display = "block";
                var blockConfirm = document.getElementById("confirmBlock");
                blockConfirm.innerHTML = "";
                bM.style.display = "none";
                overlayme.style.display = "none";
                toast.success('Account was blocked. Page will Reload', {
                    onClose: () => {
                        location.reload();
                    },
                    autoClose: 3000,
                    className: 'block-toast'
                });
            }
            else {
                var blockConfirm = document.getElementById("confirmBlock");
                blockConfirm.innerHTML = "";
                overlayme.style.display = "none";
                toast.error('Block was not successful. Page will Reload', {
                    onClose: () => {
                        location.reload();
                    },
                    autoClose: 3000
                });
            }

        });

    }


    function cancel(overlayme) {
        /* code executed if cancel is clicked */
        var markConfirm = document.getElementById("confirmMark");
        var ValidateConfirm = document.getElementById("confirmValidate");
        ValidateConfirm.innerHTML = "";
        var blockConfirm = document.getElementById("confirmBlock");
        blockConfirm.innerHTML = "";
        var extendConfirm = document.getElementById("confirmExtend");
        extendConfirm.innerHTML = "";
        var extensionDate = document.getElementById("extensionDate");
        extensionDate.style.display = "none";
        overlayme.style.display = "none";
        extendConfirm.style.display = "none";
        ValidateConfirm.style.display = "none";
        blockConfirm.style.display = "none";
        markConfirm.style.display = "none";

    }


    const taskList = tasks.map(task => (
        <TodoItem
            id={task.client_url}
            name={task.client_url}
            completed={task.pending_activation}
            key={task.client_url}
            contact={task.contact_email}
            toBeValidated={task.pending_verification}
            toBeActivated={task.pending_activation}
            allowValidation={task.allow_validation}
            allowBlock={task.allow_block}
            allowLead={task.allow_lead}
            allowExtend={task.allow_extend}
            expirationDate={task.expiration_date}
            createdDate={task.created_date}
            accountUrl={task.accounturl}
            packageName={task.product_package_name}
            phone={task.contact_phone}
            rowNum={task.rownum}
            salesPerson={task.sales_person_name}
            trialEnd={task.trial_ends_in}
            contactName={task.contact_name}
            lastLogin={task.last_login_date_cst}
            validateTrial={handleValidateTask}
            extendTrial={handleExtendTrial}
            blockTrial={handleBlockTrial}
            markTrial={handleMarkTrial}
        />
    ));

    const listHeadingRef = useRef(null);
    const prevTaskLength = usePrevious(tasks.length);


    useEffect(() => {
        if (tasks.length - prevTaskLength === -1) {
            listHeadingRef.current.focus();
        }
    }, [tasks.length, prevTaskLength]);

    return (
        <div className="data-area-div">
            <h5 id='mainTitle'><center><strong>AlertOps Trial Accounts</strong></center></h5>
            <TodoForm addTask={handleAddTask} />
            <div id="filterOptions" className="filter-container">
                <div className="filter-item">
                    <input type="checkbox" value="canvasBranch" id="cb1" className="cb-btn" onClick={filterInvalidAccounts} />
                    <label className="d-flex mb-0" htmlFor="cb1" id="lcb1">Yet-To-Be-Validated</label>
                </div>

                <div className="filter-item">
                    <input type="checkbox" value="canvasBranch" id="cb2" className="cb-btn" onClick={filterActiveAccounts} />
                    <label className="d-flex mb-0" htmlFor="cb2" id="lcb2">Active Accounts</label>
                </div>

                <div className="filter-item">
                    <input type="checkbox" value="canvasBranch" id="cb3" className="cb-btn" onClick={filterValidInactiveAccounts} />
                    <label className="d-flex mb-0" htmlFor="cb3" id="lcb3">Valid/Inactive Accounts</label>
                </div>

                <div className="filter-item">
                    <input type="checkbox" value="canvasBranch" id="cb4" className="cb-btn" onClick={removeFilters} defaultChecked={true} />
                    <label className="d-flex mb-0" htmlFor="cb4" id="lcb4">All Accounts</label>
                </div>
            </div>
            <br></br>
            <h2 id="list-heading" tabIndex="-1" ref={listHeadingRef}></h2>
            <ToastContainer className="toastMessage" />
            <div className="overlay" id="dialog-container">
                <div className="popup">

                    <p id="confirmValidate"></p>
                    <p id="confirmExtend"></p>
                    <p id="confirmMark">Are you sure you want to add this as a Lead in Pipedrive ?</p>
                    <p id="confirmBlock"></p>
                    <p className="extendMess" id="eMess">Trial Extended for 7 days...Page will reload automatically...</p>
                    <p className="extendFailMess" id="eFailMess">Extension unsuccessful!...Page will reload automatically...</p>
                    <p className="markMess" id="mMess">Signup added as Lead !...Page will reload automatically...</p>
                    <p className="markFailMess" id="mFailMess">Signup was not added as a Lead !...Page will reload automatically...</p>
                    <p className="validateMess" id="vMess">Signup Validated...Page will reload automatically...</p>
                    <p className="validateFailMess" id="vFailMess">Signup was not Validated!...Page will reload automatically...</p>
                    <p className="blockMess" id="bMess">Signup Blocked...Page will reload automatically...</p>
                    <div className="extendDate" id="extensionDate">
                        &#128197;<DatePicker
                            value={new Date(new Date(selectedDate).getTime() + 7 * 24 * 60 * 60 * 1000)}
                            selected={selectedDate}
                            onSelect={(date) => setSelectedDate(date)}
                            minDate={new Date(actualexpDate)} // Restrict dates before expiration date
                            maxDate={new Date(new Date(actualexpDate).getTime() + 7 * 24 * 60 * 60 * 1000)} // 7 days from today
                            placeholderText="Select Date(7 days max)"
                        />
                    </div>
                    <br />
                    <div className="text-center">
                        <button className="dialog-btn btn-cancel" id="cancel">Cancel</button>
                        <button className="dialog-btn btn-primary" id="confirm">Yes</button>
                    </div>
                </div>
            </div>
            <div className="table-wrapper">
                {/* Scroll Left Arrow */}
                <div className="scroll-arrow left" onClick={() => scrollTable("left")}>
                    {"<"}
                </div>
                <div className="table-container" ref={tableContainerRef}>
                    <table className="task-table table-striped">
                        <thead>
                            <tr>
                                <th className="sortable" onClick={() => handleSort('client_url')}>
                                    Account ↓↑
                                </th>
                                <th className="package-col">Package</th>
                                <th className="sortable" onClick={() => handleSort('last_login_date_cst')}>
                                    Last Login ↓↑
                                </th>
                                <th className="contact-col">Contact Email / Phone</th>
                                <th className="sortable" onClick={() => handleSort('created_date')}>
                                    Created ↓↑
                                </th>
                                <th className="sortable" onClick={() => handleSort('trial_ends_in')}>
                                    Expires ↓↑
                                </th>
                                <th className="actions-col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {taskList}
                        </tbody>
                    </table>
                </div>
                {/* Scroll Right Arrow */}
                <div className="scroll-arrow right" onClick={() => scrollTable("right")}>
                    {">"}
                </div>
            </div>
            {/* <ListGroup className="todo-list">
                {taskList}
            </ListGroup> */}
        </div>
    );
}