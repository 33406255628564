import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";

import { RouteGuard } from './components/RouteGuard';
import { PageLayout } from "./components/PageLayout";
import { TodoList } from "./pages/TodoList";
import { signupdetails } from "./pages/signupDetails";

import { appRoles } from "./authConfig";
import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { cspage } from "./pages/csPage";
import { TrialList } from "./pages/trialSignupsList";
import "./styles/App.css";

import { useMsalAuthentication } from '@azure/msal-react';
import { loginRequest } from "./authConfig";

const Pages = () => {
  const { instance } = useMsal();
  let userRoles = [];
  const currentAccount = instance.getActiveAccount();
  if (currentAccount && currentAccount.idTokenClaims['roles']) {
    userRoles = currentAccount.idTokenClaims['roles'];
  }
  const canAccessCSPage = userRoles.some(role => [appRoles.CSMember].includes(role));
  const canAccessTodoList = userRoles.some(role => [appRoles.SalesMember,appRoles.TrialAdmin,appRoles.GlobalAdmin].includes(role));

  return (
    <Switch>
      <RouteGuard
        path='/signupdetails'
        roles={[appRoles.TaskUser, appRoles.TaskAdmin, appRoles.TrialAdmin, appRoles.GlobalAdmin,appRoles.SalesMember]}
        Component={signupdetails}
      />
      <RouteGuard
        path='/signuplist'
        roles={[appRoles.TaskUser, appRoles.TaskAdmin, appRoles.TrialAdmin, appRoles.GlobalAdmin,appRoles.SalesMember]}
        Component={TrialList}
      />
      <RouteGuard
        path='/cspage'
        roles={[appRoles.CSMember,appRoles.GlobalAdmin]}
        Component={cspage}
      />
      {canAccessCSPage ? (
        <RouteGuard
          exact
          path=""
          roles={[appRoles.CSMember,appRoles.GlobalAdmin]}
          Component={cspage}
        />
      ) : canAccessTodoList ? (
        <RouteGuard
          exact
          path=""
          roles={[appRoles.SalesMember,appRoles.TrialAdmin,appRoles.GlobalAdmin]}
          Component={TodoList}
        />
      ) : null}
    </Switch>
  )
}

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be 
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication 
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the 
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = ({ instance }) => {
  useMsalAuthentication(InteractionType.Redirect,loginRequest);
  //console.log(useMsalAuthentication(InteractionType.Redirect,loginRequest));
  return (
    <Router>
      <MsalProvider instance={instance}>
          <PageLayout>
            <AuthenticatedTemplate>
              <Pages instance={instance} />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                
            </UnauthenticatedTemplate>
          </PageLayout>
      </MsalProvider>
    </Router>
  );
}

export default App;