import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useMsalAuthentication} from "@azure/msal-react";

import { NavigationBar } from "./NavigationBar";

import { Nav, Navbar, Button, Dropdown, DropdownButton} from "react-bootstrap";

import { loginRequest } from "../authConfig";

import { InteractionStatus, InteractionType } from "@azure/msal-browser";

export const PageLayout = (props) => {
    
    /**
     * Most applications will need to conditionally render certain components based on whether a user is signed in or not. 
     * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will 
     * only render their children if a user is authenticated or unauthenticated, respectively.
     */
    const { instance } = useMsal();
    useMsalAuthentication(InteractionType.Redirect,loginRequest);
    //console.log(useMsalAuthentication(InteractionType.Redirect, loginRequest));
    return (
        <>
            <NavigationBar />
            <br />
            <br />
            {props.children}
            <br />
            <footer>
                    <center><strong>AlertOps &copy;</strong>
                        <a href="https://alertops.com" target="_blank"> </a>
                    </center>
            </footer>
            <AuthenticatedTemplate>
                
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <h5><center><strong>...........</strong></center></h5>
                {/* <h5><center><strong>Login and you will be redirected to the 'Signups' page if you have valid roles</strong></center></h5> */}
                {/* <center><Button variant="dark" className="ml-auto" drop="left" title="Sign In" onClick={() => instance.loginRedirect(loginRequest)}>Sign In</Button></center> */}
            </UnauthenticatedTemplate>
        </>
    );
};