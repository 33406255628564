import React from 'react';

const EnableInvoiceButton = ({ onClick }) => {
  return (

      <button variant="primary" className="invoiceButton" onClick={onClick}>
        Add Annual Invoice
      </button>

  );
};

export default EnableInvoiceButton;