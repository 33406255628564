import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { getTasks } from "../fetch";

import { ListView } from '../components/ListView';

const TodoListContent = () => {
    const { instance } = useMsal();
    const [todoListData, setTodoListData] = useState(null);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [showData, setShowData] = useState(false);
    const [errorMessage, setErrorMessage] = useState(''); // State for storing the error message
    useEffect(() => {
        if (!todoListData) {
            setIsLoadingData(true);
            setShowData(true);
            getTasks().then(response => {
                if (response.status === 200 || response.status === 201) {
                    const dataT = response.data;
                    setTodoListData(dataT);
                    setIsLoadingData(false);
                } else if (response.status === 403) {
                    // Handle 403 Forbidden Error
                    setErrorMessage('You do not have permission to view this content.');
                    setIsLoadingData(false);
                } else {
                    // Handle other errors
                    setErrorMessage('An error occurred while fetching data.');
                    setIsLoadingData(false);
                }
            }).catch(error => {
                // Handle errors if `getTasks` fails (e.g., network error)
                console.error('Fetching tasks failed:', error);
                setErrorMessage('Failed to load data.');
                setIsLoadingData(false);
            });
        }
    }, []); // Ensure the effect runs once upon mount
    
    if (isLoadingData) {
        return <center><div className="loader"></div></center>;
    }
    
    if (errorMessage) {
        return <div className="error-message">{errorMessage}</div>;
    }
    
    if (showData && todoListData) {
        return <ListView todoListData={todoListData} />;
    }
    
    return null; // Render nothing if there are no data and no loading/error state
};

export const TrialList = () => {
    return <TodoListContent />;
};
