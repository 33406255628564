import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, NavLink } from 'react-router-dom';
import { Nav } from "react-bootstrap";
import { useHistory } from "react-router-dom";


export const TodoItem = (props) => {

    const [isEditing, setEditing] = useState(false);
    const [newName, setNewName] = useState('');

    const [disable, setDisable] = React.useState(false);
    const buttonRef = useRef();

    const extendButtonRef = useRef();
    const blockButtonRef = useRef();
    const isValidRef = useRef();
    const isLeadRef = useRef();

    const history = useHistory();

    const handleRowClick = () => {
        history.push(`/signupdetails?account=${props.name}`);
    };


    const extendTrial = (event) => {
        event.stopPropagation();
        props.extendTrial(props.id, props.expiration_date, props.expirationDate);

    }


    const blockTrial = (event) => {
        event.stopPropagation();
        props.blockTrial(props.id, props.contact, props.accountUrl, props.contactName);
    }

    const validateSignup = (event) => {
        event.stopPropagation();
        props.validateTrial(props.id, props.contact, props.accountUrl, props.contactName);
        if (props.toBeValidated == 0) {
            //toast.success("Successful");
            buttonRef.current.disabled = true;
            extendButtonRef.current.disabled = false;
        }
    }

    const markTrial = (event) => {
        event.stopPropagation();
        props.markTrial(props.id, props.contact, props.accountUrl, props.contactName, props.phone, props.packageName);
    }

    const redirectTo = (event) => {
        event.stopPropagation();
        window.location = props.accountUrl;
    }


    function formatDate(selDate) {
        const exp_createDate_Obj = new Date(selDate);
        let dateFormatted;
        const weekday = new Array("Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat");
        let day = weekday[exp_createDate_Obj.getDay()];
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let month = months[exp_createDate_Obj.getMonth()];
        let hours = exp_createDate_Obj.getHours();
        const ampmSwitch = (hours > 12) ? "PM" : "AM";
        if (hours > 12) {
            hours -= 12;

        }
        else if (hours === 0) {
            hours = 12;
        }

        dateFormatted = month + " " + exp_createDate_Obj.getDate() + "," + exp_createDate_Obj.getFullYear() + " at " + exp_createDate_Obj.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
        return dateFormatted;
    }


    const validateCondition = props.toBeValidated;
    const activateCondition = props.toBeActivated;
    const allow_block = props.allowBlock;
    const allow_validation = props.allowValidation;
    const allow_lead = props.allowLead;
    const allow_extend = props.allowExtend;
    const row_Number = props.rowNum;

    const buttStyle = {
        display: "none"
    }

    let validateButton;
    let blockButton;
    let leadButton;
    let isValidIcon;
    let isNotActiveButton;

    if (allow_validation == 1) {
        validateButton = <Button className="validateButton" variant="outline-success" size="sm" ref={buttonRef} onClick={validateSignup} >Validate</Button>;
        isValidIcon = <span className="isNotValid" ref={isValidRef}
            title="Signup Not Validated">&#x26A0;</span>;
    }
    else {
        validateButton = <Button variant="outline-success" className="validateButton" size="sm" style={buttStyle} disabled={true} ref={buttonRef}>Validate</Button>;
        isValidIcon = <span className="isValid"
            title="Signup Validated" ref={isValidRef}>&#x2714;</span>;
    }

    if (allow_block == 1) {
        blockButton = <Button variant="outline-danger" className="blockButton" size="sm" onClick={blockTrial} ref={blockButtonRef}>Block</Button>;

    }
    else {
        blockButton = <Button variant="outline-danger" className="blockButton" size="sm" style={buttStyle} disabled={true} onClick={blockTrial} ref={blockButtonRef}>Block</Button>;
    }

    if (allow_lead == 1) {
        leadButton = <Button variant="outline-dark" className="leadButton" size="sm" onClick={markTrial} ref={isLeadRef}>Lead</Button>;
    }
    else {
        leadButton = <Button variant="outline-dark" className="leadButton" size="sm" style={buttStyle} disabled={true} onClick={markTrial} ref={isLeadRef}>Lead</Button>;
    }


    let extendButton;

    if (allow_extend == 1) {
        extendButton = <Button variant="outline-info" className="extendButton" size="sm" onClick={extendTrial} ref={extendButtonRef}>Extend</Button>;


    }
    else {
        extendButton = <Button variant="outline-info" className="extendButton" size="sm" disabled={true} style={buttStyle} onClick={extendTrial} ref={extendButtonRef}>Extend</Button>;
    }

    if (validateCondition == 0 && activateCondition === true) {
        isNotActiveButton = <p className="isInactive">Signup Validated but <strong>Not Activated!</strong></p>
        extendButton = <Button variant="outline-info" className="extendButton" size="sm" style={buttStyle} onClick={extendTrial} ref={extendButtonRef}>Extend</Button>;
        validateButton = <Button variant="outline-success" className="validateButton" size="sm" disabled={true} style={buttStyle} ref={buttonRef}>Validate</Button>;
        blockButton = <Button variant="outline-danger" className="blockButton" size="sm" style={buttStyle} disabled={true} onClick={blockTrial} ref={blockButtonRef}>Block</Button>;

    }

    const expDate = props.expirationDate;
    const createdDate = props.createdDate;
    const lastLoginDate = props.lastLogin;
    let expDate_formatted;
    let createDate_formatted;
    let lastLogin_formatted

    expDate_formatted = formatDate(expDate);
    createDate_formatted = formatDate(createdDate);

    if (lastLoginDate === null) {
        lastLogin_formatted = "";
    }
    else {
        lastLogin_formatted = formatDate(lastLoginDate);
    }


    // Prepare the status message for the "Actions" column
    const statusMessage = (props.toBeValidated === 0 && props.toBeActivated === true)
        ? "Signup Validated but Not Activated!"
        : "";

    return (

        <tr onClick={handleRowClick} className="task-row hoverable" >
            <td className="task-account">
                {props.name}
                <span className="redirect-icon" onClick={redirectTo} title="Redirect to Website">
                    &nbsp;&#8663;
                </span>
            </td>
            <td className="task-package">{props.packageName}</td>
            <td className="task-last-login">{lastLogin_formatted}</td>
            <td className="task-contact">{props.contact} / {props.phone}</td>
            <td className="task-created">{createDate_formatted}</td>
            <td className="task-expires">{props.trialEnd}</td>
            <td className="task-actions">
                <div className="button-span">
                    <ButtonGroup className="todo-view-btn">
                        {isNotActiveButton}
                        {blockButton}
                        {validateButton}
                        {extendButton}
                        {leadButton}
                    </ButtonGroup>
                </div>
            </td>
        </tr>
    );
}
