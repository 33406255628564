import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the CSS for styling

const EnableExtendTrialButton = ({ onClick }) => {
  return (

      <button variant="primary" className="extendTrialButton" onClick={onClick}>
        Extend Trial
      </button>

  );
};

export default EnableExtendTrialButton;