import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Nav, Navbar, Button, Container } from "react-bootstrap";
import React from "react";
import { loginRequest } from "../authConfig";
import logo from "../public/images/AlertOps_Logo.jpg";
import "../styles/App.css";

export const NavigationBar = (props) => {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginPopup(loginRequest)
            .catch((error) => console.log(error))
    };

    return (
        <>
            <Navbar expand="lg" className="color-custom" variant="dark">
                <Container fluid>
                    <Navbar.Brand href="/signuplist">
                        <img src={logo} className="navbarLogo" alt="AlertOps" />
                    </Navbar.Brand>
                    
                    {/* Toggle for mobile view */}
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        {/* Left-aligned links */}
                        <Nav className="ms-auto">
                            <AuthenticatedTemplate>
                                <Nav.Link as={Button} id="signupbutton" variant="dark" className="signupNav" href="/signuplist">
                                    <strong>Signups</strong>
                                </Nav.Link>
                                <Nav.Link as={Button} id="signupbutton" variant="dark" className="signupNav" href="/cspage">
                                    <strong>Customer</strong>
                                </Nav.Link>
                            </AuthenticatedTemplate>
                        </Nav>

                        {/* Right-aligned "Sign Out" button */}
                        <Nav className="ml-auto">
                            <AuthenticatedTemplate>
                                <Button 
                                    variant="warning" 
                                    onClick={() => instance.logoutRedirect({ postLogoutRedirectUri: "/" })}
                                >
                                    <strong>Sign Out</strong>
                                </Button>
                            </AuthenticatedTemplate>
                            <UnauthenticatedTemplate>
                                <Button onClick={handleLogin} variant="outline-light">Login</Button>
                            </UnauthenticatedTemplate>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};
