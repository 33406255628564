import React from 'react';

const EnablePlanChangeButton = ({ onClick }) => {
  return (

      <button variant="primary" className="planChangeButton" onClick={onClick}>
        Change Plan
      </button>

  );
};

export default EnablePlanChangeButton;