import React, { Fragment, useEffect, useState, useRef } from "react";
import { getSignupDetails, getDealFromSubdomain, getDealDetailsFromPipeDealID, getSalesGuyFromOwnerID, getPackages, changePackage, getAdmins, changeOwner, extendTrial, getInvoicePackage, getServiceProviders, getCSPeople, getSalesPeople, addInvoice, getSubscriptionDetails, getSignupDetailsForCust } from "../fetch";
import { useLocation } from 'react-router-dom';
import { Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { enableLCR } from '../fetch';
import { useMsal } from "@azure/msal-react";
import EnableLCRButton from './EnableLCRButton';
import EnablePlanChangeButton from "./EnablePlanChange";
import EnableOwnerChangeButton from "./EnableOwnerChange";
import EnableExtendTrialButton from "./EnableExtendButton";
import EnableInvoiceButton from "./EnableInvoiceButton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from 'react-router-dom';
/**
 * useMsal is hook that returns the PublicClientApplication instance, 
 * an array of all accounts currently signed in and an inProgress value 
 * that tells you what msal is currently doing. For more, visit: 
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
 */


export const signupdetails = () => {



    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const param1 = queryParams.get('account');
    const history = useHistory();



    var dataT;
    const dealFieldsToShow = [
        'Deal ID',
        'Creator.name',
        'Title',
        'Value',
        'Currency',
        'Added Time',
        'Last Updated Time',
        'Status',
        'Person',
        'Org Name',
        'Annual Fee'
    ];



    const [todoListData, setTodoListData] = useState([]);
    const [isLoadingData, setisLoadingData] = useState(false);
    const [showData, setShowData] = useState(false);
    const [reloaded, setReloaded] = useState(false);
    const [selectedDate_I, setSelectedDate_I] = useState(null);
    const [matchedEmployeeId, setMatchedEmployeeId] = useState('');
    const [userCount, setuserCount] = useState(null);
    const [stakeholderCount, setstakeholderCount] = useState(null);
    const [annualFee, setannualFee] = useState('');
    const [stakeholderFee, setStakeholderFee] = useState('');
    const [subscriptionData, setSubscriptionData] = useState([]);
    const [showDetailsPopup, setShowDetailsPopup] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModal_package, setShowModalPackage] = useState(false);
    const [showModal_owner, setShowModalOwner] = useState(false);
    const [text1, setText1] = useState('');
    const [text2, setText2] = useState('');
    const [text3, setText3] = useState('');
    const [text4, setText4] = useState('');
    const formRef = useRef(null);
    const text1Ref = useRef();
    const text2Ref = useRef();
    const text3Ref = useRef();
    const text4Ref = useRef();
    const userCount_ref = useRef();
    const stakeholderCount_ref = useRef();
    const annualFee_ref = useRef();
    const stakeholderFee_ref = useRef();
    const modalRef = useRef(null);
    const modalRef_planChange = useRef(null);
    const modalRef_ownerChange = useRef(null);
    const [availablePackages, setAvailablePackages] = useState([]);
    const [availableOwners, setAvailableOwners] = useState([]);
    const [availableCustomerSuccess, setAvailableCustomerSuccess] = useState([]);
    const [availableSalesPeople, setAvailableSalesPeople] = useState([]);
    const [availableServiceProvider, setAvailableServiceProvider] = useState([]);
    const [invoicePlans, setInvoicePlans] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState('');
    const [selectedOwner, setSelectedOwner] = useState('');
    const [selectedInvoicePlan, setSelectedInvoicePlan] = useState('');
    const [selectedCustomerSuccess, setSelectedCustomerSuccess] = useState('');
    const [selectedServiceProvider, setSelectedServiceProvider] = useState('');
    const [salesPersonContent, setSalesPersonContent] = useState(null);
    const [customerYes, setCustomerYes] = useState(false);
    const [salesPersonEmail, setSalesPersonEmail] = useState('');
    const [dealIdContent, setDealIdContent] = useState(null);
    const [dealDetails, setDealDetails] = useState(null);
    const [dealId, setDealId] = useState(null);
    const [accountType, setAccountType] = useState(null);
    const [csPerson, setCsPerson] = useState(null);
    const [userCount_main, setuserCount_main] = useState(null);
    const [stakeholderCount_main, setStakeHolderCount_main] = useState(null);
    const [availableAdmins, setAvailableAdmins] = useState([]);
    const [selectedAdmin, setSelectedAdmin] = useState('');
    const { instance, accounts, inProgress } = useMsal();
    const currentAccount = instance.getActiveAccount();
    const role = currentAccount.idTokenClaims['roles'].includes("Global.Admin");
    const getRoles = currentAccount.idTokenClaims['roles'];
    const s_role = getRoles.some((r) => r === "Global.Admin" || r === "Sales.Member");
    const cs_role = currentAccount.idTokenClaims['roles'].includes("CS.Member");
    const csRole_GlobalAdmin = getRoles.some((r) => r === "Global.Admin" || r === "CS.Member");
    const sales_role = getRoles.some((r) => r === "Global.Admin" || r === "Sales.Member" || r === "Trial.Admin");
    const invoice_role = getRoles.some((r) => r === "Global.Admin" || r === "Sales.Member" || r === "Trial.Admin");
    const trial_GlobalAdmin = getRoles.some((r) => r === "Global.Admin" || r === "Trial.Admin");
    const [current_package, setCurrent_package] = useState('');
    const [expirationDate, setExpirationDate] = useState('');
    const [allowStakeHolders, setAllowStakeHolders] = useState(null);
    const [current_owner, setCurrent_owner] = useState('');
    const [current_owner_fullname, setCurrent_owner_fullname] = useState('');
    const [minExpirationDate, setMinExpirationDate] = useState(null);

    const initialSelectedDate = minExpirationDate ? new Date(minExpirationDate) : new Date();
    initialSelectedDate.setDate(initialSelectedDate.getDate() + 7);
    const [selectedDate, setSelectedDate] = useState(initialSelectedDate);
    const [actualexpDate, setactualexpDate] = useState(initialSelectedDate);
    const modalRef_extension = useRef(null);
    const modalRef_invoice = useRef(null);
    const [showModal_extension, setShowModal_extension] = useState(false);
    const [showModal_invoice, setShowModal_invoice] = useState(false);
    const toggleModal = () => {
        setModalIsOpen(!modalIsOpen);
    };

    const handleShowModal_extension = () => {
        if (modalRef_extension.current) {
            modalRef_extension.current.show = true;
            const initialSelectedDate = new Date(minExpirationDate);
            initialSelectedDate.setDate(initialSelectedDate.getDate() + 7);
            const newDate = new Date(expirationDate);
            newDate.setDate(newDate.getDate() + 7);
            setSelectedDate(newDate)
            setactualexpDate(expirationDate)
            setMinExpirationDate(minExpirationDate);
            setShowModal_extension(true);
        }
    };

    const handleShowModal_invoice = () => {
        if (modalRef_invoice.current) {
            modalRef_invoice.current.show = true;
            setShowModal_invoice(true);
        }
    };


    const handleCloseModal_invoice = () => {
        if (modalRef_invoice.current) {
            modalRef_invoice.current.show = false;
            setShowModal_invoice(false);
        }
    };




    const handleCloseModal_extension = () => {
        if (modalRef_extension.current) {
            modalRef_extension.current.show = false;
            setShowModal_extension(false);
        }
    };

    const handleSubmit_extendTrial = (event) => {
        event.preventDefault();

        const formd = event.target;

        const formData = {
            dateId: formd.datepickerId.value

        };

        const accountUrl = todoListData[0].client_url;
        const formattedDate = changeDateFormatAndIncrementDay(formData.dateId);

        extendTrial(accountUrl, formattedDate).then((response) => {

            if ((response.status === 200 || response.status === 201) && (response.data[0].product_package_id !== 0)) {

                toast.success('Trial was Extended. Page will Reload', {
                    onClose: () => {
                        window.location.reload();
                    },
                    autoClose: 3000
                });
            }
            else {

                toast.error('Trial was not Extended. Page will Reload', {
                    onClose: () => {
                        window.location.reload();
                    },
                    autoClose: 3000
                });
            }
        });

        handleCloseModal_extension();


    };

    const handleSubmit_invoice = (event) => {

        //Get Deal Fields and Information from Pipedrive


        event.preventDefault();

        const formd = event.target;

        const stakeholderCountInput = formd.stakeholderCount;
        const stakeholderFeeInput = formd.stakeholderFee;

        const formData = {
            subscriptionStartDate: formd.subscriptionStartDate.value,
            userCount: formd.userCount.value,
            stakeholderCount: stakeholderCountInput ? stakeholderCountInput.value || 0 : 0,
            annualFee: formd.annualFee.value,
            stakeholderFee: stakeholderFeeInput ? stakeholderFeeInput.value || 0 : 0,
            customerSuccess: formd.customerSuccess.value,
            serviceProvider: formd.serviceProvider.value,
            invoicePlan: formd.invoicePackage.value,
            salespersonId: formd.matchedEmployeeId.value
        };

        const accountUrl = todoListData[0].client_url;


        addInvoice(accountUrl, formData.annualFee, formData.userCount, formData.stakeholderFee, formData.stakeholderCount, formatDateSpecific(formData.subscriptionStartDate), formData.invoicePlan, formData.customerSuccess, formData.serviceProvider, formData.salespersonId).then((response) => {
            if ((response.status === 200 || response.status === 201) && (response.data[0].product_package_id !== 0)) {
                toast.success('Invoice Added', {
                    onClose: () => {
                        window.location.reload();
                       // history.push('/cspage');
                    },
                    autoClose: 3000
                });
            }
            else {

                toast.error('Invoice addition was unsuccessful. Try again. Page will Reload', {
                    onClose: () => {
                        window.location.reload();
                    },
                    autoClose: 3000
                });
            }
        });


        handleCloseModal_invoice();
    };


    useEffect(() => {

        getSignupDetails(param1, cs_role).then(response => {
            if (response.status == 200 || response.status == 201) {
                dataT = response.data

                setTodoListData(dataT)
                setCurrent_package(dataT[0].product_package_name)
                setExpirationDate(dataT[0].expiration_date)
                setMinExpirationDate(dataT[0].expiration_date)
                setactualexpDate(dataT[0].expiration_date)
                setAccountType(dataT[0].account_type)
                setuserCount_main(dataT[0].user_count)
                if(cs_role === true){
                    setCustomerYes(true);
                }
                setCsPerson(<span><strong>CS person:</strong> {dataT[0].cs_person_name}</span>)
                setAllowStakeHolders(dataT[0].allow_stakeholders)
                setStakeHolderCount_main(dataT[0].stakeholder_count)

            }
            else if (response.status == 400) {
                getSignupDetailsForCust(param1).then(response => {
                    if (response.status == 200 || response.status == 201) {
                        dataT = response.data
                        setTodoListData(dataT)
                        setCurrent_package(dataT[0].product_package_name)
                        setExpirationDate(dataT[0].expiration_date)
                        setMinExpirationDate(dataT[0].expiration_date)
                        setactualexpDate(dataT[0].expiration_date)
                        setAccountType(dataT[0].account_type)
                        setuserCount_main(dataT[0].user_count)
                        setCsPerson(<span><strong>CS person:</strong> {dataT[0].cs_person_name}</span>)
                        setCustomerYes(true);
                        setAllowStakeHolders(dataT[0].allow_stakeholders)
                        setStakeHolderCount_main(dataT[0].stakeholder_count)
                    }
                    else {
                        setTodoListData([]);
                        alert("No such account found...")
                        if(cs_role){
                            document.location.href = "/cspage";
                            setTodoListData("");
                        }
                        else {
                            document.location.href = "/signuplist";
                            setTodoListData("");
                        }
                    }
                });
            }

            else {
                alert("No such account found...")
                if (cs_role) {
                    document.location.href = "/cspage";
                    setTodoListData("");
                }
                else {
                    document.location.href = "/signuplist";
                    setTodoListData("");
                }

            }
        }
        )

    }, []);

    //Get Salesguy
    const getSalesGuy = () => {
        let oId;
        let showSalesPerson;
        let type;
        getDealFromSubdomain(param1).then((response) => {

            if (response.data.data.items.length === 0) {


                setSalesPersonContent(<span><strong>Sales/Customer Success person: </strong> None </span>);
            }
            else {

                oId = response.data.data.items[0].item.owner.id;
                type = "Sales Person";
                getOwner(oId, type);


            }

        });

    };

    const getOwner = (oId, type) => {
        let showSalesPerson;
        getSalesGuyFromOwnerID(oId).then((response) => {
            setSalesPersonEmail(response.data.data.email);
            showSalesPerson = <p>{type}: <strong>{response.data.data.name}</strong></p>

            setSalesPersonContent(<span><strong>Sales person:</strong> {response.data.data.name}</span>);

        });

    }

    const getPipeDealID = () => {
        let dealId;
        getDealFromSubdomain(param1).then((response) => {

            if (response.data.data.items.length === 0) {
                setDealIdContent(<span style={{ display: 'none' }}><strong>(No Deal ID)</strong></span>);
            }
            else {
                dealId = response.data.data.items[0].item.id;
                setDealId(dealId);
                setDealIdContent(<span style={{ display: 'none' }}><strong>{dealId}</strong></span>);
                getAllDealInfoFromPipe(dealId)
            }


        });

    }

    const getAllDealInfoFromPipe = (dealId) => {
        getDealDetailsFromPipeDealID(dealId).then((response) => {
            const transformedDetails = Object.entries(response.data.data).map(([key, value]) => {
                // Use the key name from the window.appConfig if available, otherwise keep the original key
                const keyName = window.appConfig[key] || key;
                return { key: keyName, value: value === null ? 'N/A' : value }; // Create an object for each key-value pair
            });

            setDealDetails(transformedDetails);

        });


    }

    useEffect(() => {
        fetchSalesPeople();
    }, []);

    useEffect(() => {
        getSalesGuy();
    }, []);

    useEffect(() => {
        getPipeDealID();
    }, []);

    useEffect(() => {
        fetchPackages();
    }, []);

    useEffect(() => {
        fetchAdmins();
    }, []);

    useEffect(() => {
        fetchServiceProviders();
    }, []);

    useEffect(() => {
        fetchCSPeople();
    }, []);

    useEffect(() => {
        fetchInvoicePackage();
    }, []);

    useEffect(() => {
        getSubscriptionInfo();
    }, []);

    const getSubscriptionInfo = () => {
        getSubscriptionDetails(param1).then(response => {
            if (response.status == 200 || response.status == 201) {
                dataT = response.data
                setSubscriptionData(dataT);

            }
            else {
                setSubscriptionData([]);
            }
        });

    }


    const fetchServiceProviders = () => {
        getServiceProviders(param1).then(response => {
            if (response.status == 200 || response.status == 201) {
                dataT = response.data
                setAvailableServiceProvider(dataT);

            }
            else {
                setAvailableServiceProvider([]);
            }
        });

    }

    const fetchCSPeople = () => {
        getCSPeople(param1).then(response => {
            if (response.status == 200 || response.status == 201) {
                dataT = response.data
                setAvailableCustomerSuccess(dataT);

            }
            else {
                setAvailableCustomerSuccess([]);
            }
        });

    }

    const fetchSalesPeople = () => {
        getSalesPeople(param1).then(response => {
            if (response.status == 200 || response.status == 201) {
                dataT = response.data
                setAvailableSalesPeople(dataT);

            }
            else {
                setAvailableSalesPeople([]);
            }
        });

    }



    const fetchInvoicePackage = () => {
        getInvoicePackage(param1).then(response => {
            if (response.status == 200 || response.status == 201) {
                dataT = response.data
                setInvoicePlans(dataT);

            }
            else {
                setInvoicePlans([]);
            }
        });

    }


    //Fetch Packages 
    const fetchPackages = () => {
        getPackages(param1).then(response => {
            if (response.status == 200 || response.status == 201) {
                dataT = response.data
                setAvailablePackages(dataT);
            }
            else {
                setAvailablePackages([]);
            }
        });
    }

    //Fetch Admins 
    const fetchAdmins = () => {
        getAdmins(param1).then(response => {
            if (response.status == 200 || response.status == 201) {
                dataT = response.data
                const ownerUsername = dataT.find(obj => obj.rolename === 'Owner')?.username;
                const ownerFirstName = dataT.find(obj => obj.rolename === 'Owner')?.firstname;
                const ownerLastName = dataT.find(obj => obj.rolename === 'Owner')?.lastname;
                const ownerFullName = ownerFirstName + "," + ownerLastName;
                setCurrent_owner(ownerUsername);
                setCurrent_owner_fullname(ownerFullName);
                setAvailableOwners(dataT);
            }
            else {
                setAvailableOwners([]);
            }
        });
    }

    // Handle selection change
    const handlePackageChange = (e) => {
        setSelectedPackage(e.target.value);
    };
    const handleOwnerChange = (e) => {
        setSelectedOwner(e.target.value);
    };
    const handleServiceProvider = (e) => {
        setSelectedServiceProvider(e.target.value);
    };
    const handleInvoicePlan = (e) => {
        setSelectedInvoicePlan(e.target.value);
    };
    const handleCustomerSuccess = (e) => {
        setSelectedCustomerSuccess(e.target.value);
    };



    // Trigger the modal by setting its show prop to true
    const handleShowModal = () => {
        if (modalRef.current) {
            modalRef.current.show = true;
            setShowModal(true);
        }
    };

    const handleShowModal_forPackage = () => {
        if (modalRef_planChange.current) {
            modalRef_planChange.current.show = true;
            setShowModalPackage(true);
        }
    };

    const handleShowModal_forOwner = () => {
        if (modalRef_ownerChange.current) {
            modalRef_ownerChange.current.show = true;
            setShowModalOwner(true);
        }
    };

    // Hide the modal by setting its show prop to false
    const handleCloseModal = () => {
        if (modalRef.current) {
            modalRef.current.show = false;
            setShowModal(false);
        }
    };

    const handleCloseModal_forPackage = () => {
        if (modalRef_planChange.current) {
            modalRef_planChange.current.show = false;
            setShowModalPackage(false);
        }
    };

    const handleCloseModal_forOwner = () => {
        if (modalRef_ownerChange.current) {
            modalRef_ownerChange.current.show = false;
            setShowModalOwner(false);
        }
    };

    //const handleShowModal = () => setShowModal(true);
    //const handleCloseModal = () => setShowModal(false);
    const handleSubmit = (event) => {
        event.preventDefault();

        const formd = event.target;

        const formData = {
            twilioId: formd.text1.value,
            state: formd.text2.value,
            country: formd.text3.value,
            areacode: formd.text4.value
        };

        const accountUrl = todoListData[0].client_url;

        enableLCR(accountUrl, formData.twilioId, formData.country, formData.state, formData.areacode).then((response) => {
            if ((response === 200 || response === 201 || response === 204)) {


                toast.success('Live Call Routing was enabled. Page will Reload now', {
                    onClose: () => {
                        window.location.reload();
                    },
                    autoClose: 3000
                });
            }
            else {

                toast.error('Live Call Routing was not enabled. Page will Reload', {
                    onClose: () => {
                        window.location.reload();
                    },
                    autoClose: 3000
                });
            }
        });

        handleCloseModal();

    };

    const handleSubmit_changePlan = (event) => {
        event.preventDefault();

        const formd = event.target;

        const formData = {
            selected_package: formd.text2.value,
            package_id: formd.text2.key

        };

        const accountUrl = todoListData[0].client_url;
        const selectedPackage_Id = availablePackages.find((pack) => pack.name === formData.selected_package)?.id;



        changePackage(accountUrl, formData.selected_package, selectedPackage_Id).then((response) => {
            if ((response.status === 200 || response.status === 201) && (response.data[0].product_package_id !== 0)) {


                toast.success('Package Changed. Page will Reload', {
                    onClose: () => {
                        window.location.reload();
                    },
                    autoClose: 3000
                });
            }
            else {

                toast.error('Plan change was unsuccessful. Page will Reload', {
                    onClose: () => {
                        window.location.reload();
                    },
                    autoClose: 3000
                });
            }
        });

        handleCloseModal_forPackage();



    };

    const handleSubmit_changeOwner = (event) => {
        event.preventDefault();

        const formd = event.target;

        const formData = {
            ownerChangeUsername: formd.text3.value

        };

        const accountUrl = todoListData[0].client_url;



        changeOwner(accountUrl, formData.ownerChangeUsername).then((response) => {
            if ((response === 200 || response === 201 || response === 204)) {

                toast.success('Owner Updated. Page will Reload', {
                    onClose: () => {
                        window.location.reload();
                    },
                    autoClose: 3000
                });
            }
            else {

                toast.error('Owner Update was unsuccessful. Page will Reload', {
                    onClose: () => {
                        window.location.reload();
                    },
                    autoClose: 3000
                });
            }
        });

        handleCloseModal_forOwner();



    };

    // Use useEffect to handle side effects from changes to salesPersonEmail or availableSalesPeople
    useEffect(() => {
        const employeeEmailPrefix = salesPersonEmail.split('@')[0].toLowerCase();
        const matchedEmployee = availableSalesPeople.find(person => person.employee.toLowerCase() === employeeEmailPrefix);

        if (matchedEmployee) {
            setMatchedEmployeeId(matchedEmployee.employee_id);
        } else {
            setMatchedEmployeeId('0'); // Use '0' or any other placeholder that indicates 'no match found'
        }
    }, [salesPersonEmail, availableSalesPeople]); // Depend on salesPersonEmail and availableSalesPeople to re-run this effect

    const handleUserCountChange = (e) => {
        const newCount = parseInt(e.target.value, 10);
        // Check if the parsed number is a valid number
        if (!isNaN(newCount)) {
            setuserCount_main(newCount);
        }
    };

    const handleUserCountBlur = () => {
        // Ensure the value doesn't go below the initial user count
        if (userCount < userCount_main) {
            setuserCount_main(userCount_main);
        }
    }

    const handleStakeholderCountChange = (e) => {
        const newCount = parseInt(e.target.value, 10);
        // Check if the parsed number is a valid number
        if (!isNaN(newCount)) {
            setStakeHolderCount_main(newCount);
        }
    };

    const handleStakeholderCountBlur = () => {
        // Ensure the value doesn't go below the initial user count
        if (stakeholderCount < stakeholderCount_main) {
            setStakeHolderCount_main(stakeholderCount_main);
        }
    }



    function formatDate(selDate) {
        const exp_createDate_Obj = new Date(selDate);
        let dateFormatted;
        const weekday = new Array("Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat");
        let day = weekday[exp_createDate_Obj.getDay()];
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let month = months[exp_createDate_Obj.getMonth()];
        let hours = exp_createDate_Obj.getHours();
        const ampmSwitch = (hours > 12) ? "PM" : "AM";
        if (hours > 12) {
            hours -= 12;

        }
        else if (hours === 0) {
            hours = 12;
        }

        dateFormatted = month + " " + exp_createDate_Obj.getDate() + "," + exp_createDate_Obj.getFullYear() + " at " + exp_createDate_Obj.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
        return dateFormatted;
    }


    const formatNames = (names) => {
        if (names === null) {
            return "None";
        }
        //return names.replace(/\\n/g, <br />);
        const namesArray = names.split("\\n");
        return namesArray.map((name, index) => (
            <React.Fragment key={index}>
                {name}
                <br />
            </React.Fragment>
        ));

    };

    function formatDateSpecific(dateString) {
        // Split the string into day, month, and year parts
        const [month, day, year] = dateString.split('/');

        // Return the date in the desired format
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }


    function changeDateFormatAndIncrementDay(dateVal) {
        // Split the date string into parts: month, day, and year
        const parts = dateVal.split('/');

        // Create a Date object with the original date
        const originalDate = new Date(`${parts[2]}-${parts[0]}-${parts[1]}`);

        // Increment the day by 1
        originalDate.setDate(originalDate.getDate() + 1);

        // Get the year, month, and day components of the updated date
        const year = originalDate.getFullYear();
        const month = originalDate.getMonth() + 1; // Months are 0-based
        const day = originalDate.getDate() + 1;

        // Ensure two-digit formatting for month and day
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;

        // Create the final date string in 'yyyy-mm-dd' format
        const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;

        return formattedDate;
    }



    return (
        <div>

            <div>
                <strong>&nbsp;&nbsp; Account:</strong> <span>{param1} &nbsp;&nbsp; | &nbsp;&nbsp;{cs_role === true || customerYes === true ? csPerson : salesPersonContent} &nbsp;&nbsp; {dealIdContent} &nbsp;&nbsp; {trial_GlobalAdmin === true ? <EnableLCRButton onClick={handleShowModal} /> : null} &nbsp;&nbsp; {sales_role === true ? <EnablePlanChangeButton onClick={handleShowModal_forPackage} /> : null} &nbsp;&nbsp; {csRole_GlobalAdmin === true ? <EnableOwnerChangeButton onClick={handleShowModal_forOwner} /> : null} &nbsp;&nbsp; {sales_role === true ? <EnableExtendTrialButton onClick={handleShowModal_extension} /> : null}</span>
            </div>

            <br />
            <div>
                <strong>&nbsp;&nbsp; Account Type:</strong> <span>{accountType}  &nbsp;&nbsp; {accountType === "Trial" ? <EnableInvoiceButton onClick={handleShowModal_invoice} /> : null} </span>
            </div>
            <br />
            <div className="main-container" style={{ display: 'flex', justifyContent: 'space-between' }}>

                <div className="info-container">

                    {todoListData.map((acc) => (

                        <div key={acc.client_url} className="info-list">
                            <div className="info-pair">
                                <span className="info-key">Account URL:</span>
                                <span className="info-value">{acc.client_url}</span>
                            </div>
                            <div className="info-pair">
                                <span className="info-key">Created | Expires:</span>
                                <span className="info-value">{formatDate(acc.created_date)}&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;{formatDate(acc.expiration_date)} </span>
                            </div>
                            <div className="info-pair">
                                <span className="info-key">Package:</span>
                                <span className="info-value">{acc.product_package_name}</span>
                            </div>
                            <div className="info-pair">
                                <span className="info-key">Timezone:</span>
                                <span className="info-value">{acc.timezone}</span>
                            </div>
                            <div className="info-pair">
                                <span className="info-key">Last Login Date:</span>
                                <span className="info-value">{formatDate(acc.last_login_date_cst)}</span>
                            </div>
                            <div className="info-pair">
                                <span className="info-key">Account Owner:</span>
                                <span className="info-value">{current_owner_fullname}</span>
                            </div>
                            <div className="info-pair">
                                <span className="info-key">Account Owner Email | Phone:</span>
                                <span className="info-value">{acc.contact_email}&nbsp;&nbsp; | &nbsp; &nbsp;  {acc.contact_phone}</span>
                            </div>
                            <div className="info-pair">
                                <span className="info-key">User Count:</span>
                                <span className="info-value">{acc.user_count}</span>
                            </div>
                            <div className="info-pair">
                                <span className="info-key">Group Count:</span>
                                <span className="info-value">{acc.groups_count}</span>
                            </div>
                            <div className="info-pair">
                                <span className="info-key">Schedule Count:</span>
                                <span className="info-value">{acc.schedulecount}</span>
                            </div>
                            <div className="info-pair">
                                <span className="info-key">Escalation Policy Count:</span>
                                <span className="info-value">{acc.escalation_policy_count}</span>
                            </div>
                            <div className="info-pair">
                                <span className="info-key">Workflow Count:</span>
                                <span className="info-value">{acc.workflow_count}</span>
                            </div>
                            <div className="info-pair">
                                <span className="info-key">Alerts Count:</span>
                                <span className="info-value">{acc.alerts_count}</span>
                            </div>
                            <div className="info-pair">
                                <span className="info-key">Last Alert Created Date:</span>
                                <span className="info-value">{formatDate(acc.last_alert_created_date)}</span>
                            </div>
                            <div className="info-pair inboundInt">
                                <span className="info-key">Inbound Integrations:</span>
                                <span className="info-value">{formatNames(acc.inbound_integration_names)}</span>
                            </div>
                            <div className="info-pair outboundInt">
                                <span className="info-key">Outbound Integrations:</span>
                                <span className="info-value">{formatNames(acc.outbound_integration_names)}</span>
                            </div>
                            <div className="info-pair escp_I">
                                <span className="info-key">Escalation Policies in Integrations:</span>
                                <span className="info-value">{formatNames(acc.escalation_policies_in_integrations)}</span>
                            </div>
                            <div className="info-pair escp_W">
                                <span className="info-key">Escalation Policies in Workflows:</span>
                                <span className="info-value">{formatNames(acc.escalation_policies_in_workflows)}</span>
                            </div>
                            <div className="info-pair wf_escp">
                                <span className="info-key">Workflows in Escalation Policies</span>
                                <span className="info-value">{formatNames(acc.workflowsin_escalation_policies)}</span>
                            </div>
                            <div className="info-pair o_wf">
                                <span className="info-key">Outbound Integrations in Workflows:</span>
                                <span className="info-value">{formatNames(acc.outbound_in_workflows)}</span>
                            </div>
                            <div className="info-pair o_escp">
                                <span className="info-key">Outbound Integrations in Escalation Policies:</span>
                                <span className="info-value">{formatNames(acc.outbound_in_escalation_policies)}</span>
                            </div>
                            <br />
                            <br />
                            <br />


                        </div>

                    ))}
                </div>

                {accountType === 'Customer' && (
                    <div className="customer-info" style={{ width: '600px', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }}>
                        <h3>Subscription Information</h3>
                        {/* Map through subscriptionData to print key-value pairs */}
                        {subscriptionData.map((subscription, index) => (
                            <div key={index} className="subscription-details" style={{ marginBottom: '10px' }}>
                                <div className="cust pair">
                                    <span className="key"><strong>Payment Method: </strong></span>
                                    <span className="value">{subscription.paymentmethod}</span>
                                </div>
                                <div className="cust pair">
                                    <span className="key"><strong>User Count: </strong></span>
                                    <span className="value">{subscription.userlicenses}</span>
                                </div>
                                <div className="cust pair">
                                    <span className="key"><strong>Annual Fee: </strong></span>
                                    <span className="value">{subscription.annualfee}</span>
                                </div>
                                <div className="cust pair">
                                    <span className="key"><strong>Stakeholder Fee: </strong></span>
                                    <span className="value">{subscription.stakeholderfee}</span>
                                </div>
                                <div className="cust pair">
                                    <span className="key"><strong>Stakeholder Count: </strong></span>
                                    <span className="value">{subscription.stakeholder_licenses}</span>
                                </div>
                                <div className="cust pair">
                                    <span className="key"><strong>Subscription Start Date: </strong></span>
                                    <span className="value">{subscription.subscriptionstartdate}</span>
                                </div>
                                <div className="cust pair">
                                    <span className="key"><strong>Product Package Name: </strong></span>
                                    <span className="value">{subscription.product_package_name}</span>
                                </div>
                                <div className="cust pair">
                                    <span className="key"><strong>License Term Type: </strong></span>
                                    <span className="value">{subscription.license_term_type}</span>
                                </div>
                                <div className="cust pair">
                                    <span className="key"><strong>Effective Date: </strong></span>
                                    <span className="value">{subscription.effectivedate}</span>
                                </div>

                            </div>
                        ))}
                    </div>
                )}

            </div>

            <Modal ref={modalRef} show={showModal} onHide={handleCloseModal} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Enable Live Call Routing</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form ref={formRef} onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="text1" className="form-label">Twilio Account ID:</label>
                            <input type="text" className="form-control" id="text1" ref={text1Ref} value={text1} onChange={(e) => setText1(e.target.value)} required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="text2" className="form-label">State:</label>
                            <input type="text" className="form-control" id="text2" ref={text2Ref} value={text2} onChange={(e) => setText2(e.target.value)} required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="text3" className="form-label">Country:</label>
                            <input type="text" className="form-control" id="text3" value={text3} ref={text3Ref} onChange={(e) => setText3(e.target.value)} required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="text4" className="form-label">Area Code:</label>
                            <input type="text" className="form-control" id="text4" value={text4} ref={text4Ref} onChange={(e) => setText4(e.target.value)} required />
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>

                </Modal.Body>
            </Modal>

            <Modal ref={modalRef_planChange} show={showModal_package} onHide={handleCloseModal_forPackage} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Plan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form ref={formRef} onSubmit={handleSubmit_changePlan}>
                        <div className="mb-3">
                            <label htmlFor="text1" className="form-label"><strong>Current Package:</strong> {current_package}</label>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="text2" className="form-label"><strong>Available Packages: </strong>&nbsp;</label>
                            <select
                                id="text2"
                                className="form-select"
                                value={selectedPackage}
                                onChange={handlePackageChange}
                                required
                            >
                                <option value="">Select a package</option>
                                {availablePackages.map((pack) => (
                                    <option key={pack.id} value={pack.name}>
                                        {pack.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal ref={modalRef_ownerChange} show={showModal_owner} onHide={handleCloseModal_forOwner} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Owner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form ref={formRef} onSubmit={handleSubmit_changeOwner}>
                        <div className="mb-3">
                            <label htmlFor="text1" className="form-label"><strong>Current Owner:</strong> {current_owner_fullname}</label>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="text2" className="form-label"><strong>Available Admins: </strong>&nbsp;</label>
                            <select
                                id="text3"
                                className="form-select"
                                value={selectedOwner}
                                onChange={handleOwnerChange}
                                required
                            >
                                <option value="">Select an Admin</option>
                                {availableOwners.map((admin) => (
                                    <option key={admin.username} value={admin.username}>
                                        {admin.firstname} {admin.lastname}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal ref={modalRef_extension} show={showModal_extension} onHide={handleCloseModal_extension} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Extend Trial</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form ref={formRef} onSubmit={handleSubmit_extendTrial}>
                        <p>Default date selected is 7 days from expiration date (Expires {formatDate(minExpirationDate)})</p>
                        <div className="ed" id="eDate">
                            &#128197;<DatePicker id="datepickerId"
                                value={new Date(new Date(minExpirationDate).getTime() + 7 * 24 * 60 * 60 * 1000)}
                                selected={selectedDate}
                                onSelect={(date) => setSelectedDate(date)}
                                minDate={new Date(actualexpDate)} // Restrict dates before expiration date
                                maxDate={new Date(new Date(actualexpDate).getTime() + 7 * 24 * 60 * 60 * 1000)} // 7 days from today
                                placeholderText="Select Date(7 days max)"
                            />
                        </div>
                        <br />
                        <button type="submit" className="btn btn-primary">Extend</button>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal ref={modalRef_invoice} show={showModal_invoice} onHide={handleCloseModal_invoice} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>New Annual Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form ref={formRef} onSubmit={handleSubmit_invoice}>
                        <p>Complete Fields to add invoice</p>
                        <div className="form-row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className="mb-3" style={{ flex: 1, marginRight: '10px' }}>
                                <label htmlFor="userCount" className="form-label"><strong>User Count:</strong></label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="userCount"
                                    value={userCount_main}
                                    min={userCount_main} // Set minimum to prevent HTML validation errors
                                    onChange={handleUserCountChange}
                                    onBlur={handleUserCountBlur} // Ensure the value does not go below the initial on exiting the field
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="annualFee" className="form-label"><strong>Annual Fee:</strong></label>
                                <input type="text" className="form-control" id="annualFee" value={annualFee} ref={annualFee_ref} onChange={(e) => setannualFee(e.target.value)} required />
                            </div>

                        </div>
                        <div className="form-row" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {allowStakeHolders === 1 && (
                                <div className="mb-3" style={{ flex: 1 }}>
                                    <label htmlFor="stakeholderCount" className="form-label"><strong>Stake Holder Count:</strong></label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="stakeholderCount"
                                        value={stakeholderCount_main}
                                        min={stakeholderCount_main} // Set minimum to prevent HTML validation errors
                                        onChange={handleStakeholderCountChange}
                                        onBlur={handleStakeholderCountBlur} // Ensure the value does not go below the initial on exiting the field
                                        required
                                    />
                                </div>
                            )}

                            {allowStakeHolders === 1 && (
                                <div className="mb-3">
                                    <label htmlFor="stakeholderFee" className="form-label"><strong>Stakeholder Fee:</strong></label>
                                    <input type="text" className="form-control" id="stakeholderFee" value={stakeholderFee} ref={stakeholderFee_ref} onChange={(e) => setStakeholderFee(e.target.value)} required />
                                </div>
                            )}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="text1" className="form-label"><strong>Current Package:</strong> {current_package}</label>
                        </div>


                        <div className="mb-3">
                            <label htmlFor="text2" className="form-label"><strong>Select Package: </strong>&nbsp;</label>
                            <select
                                id="invoicePackage"
                                className="form-select"
                                value={selectedInvoicePlan}
                                onChange={handleInvoicePlan}
                                required
                            >
                                <option value="">Select a package</option>
                                {invoicePlans.map((pack) => (
                                    <option key={pack.id} value={pack.id}>
                                        {pack.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="text2" className="form-label"><strong>Select Customer Success Person: </strong>&nbsp;</label>
                            <select
                                id="customerSuccess"
                                className="form-select"
                                value={selectedCustomerSuccess}
                                onChange={handleCustomerSuccess}
                                required
                            >
                                <option value="">Select Customer Success</option>
                                {availableCustomerSuccess.map((pack) => (
                                    <option key={pack.employee_id} value={pack.employee_id}>
                                        {pack.employeename}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="text2" className="form-label"><strong>Select Service Provider Type: </strong>&nbsp;</label>
                            <select
                                id="serviceProvider"
                                className="form-select"
                                value={selectedServiceProvider}
                                onChange={handleServiceProvider}
                                required
                            >
                                <option value="">Select Service Provider Type</option>
                                {availableServiceProvider.map((pack) => (
                                    <option key={pack.service_provider_type} value={pack.service_provider_type}>
                                        {pack.service_provider_type}
                                    </option>
                                ))}
                            </select>
                        </div>




                        <div className="mb-3">
                            <label htmlFor="dealFields" className="form-label"><strong>Pipedrive Deal Details: &nbsp;</strong></label>
                            <button type="button" onClick={() => setShowDetailsPopup(true)}>Show</button>
                            {showDetailsPopup && (
                                <div style={{
                                    position: 'fixed',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    backgroundColor: 'white',
                                    padding: '20px',
                                    zIndex: 1000,
                                    maxWidth: '80%',
                                    maxHeight: '80%',
                                    overflowY: 'auto',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px'
                                }}>
                                    <h2>Deal Details</h2>
                                    {dealDetails && dealDetails.length > 0 ? (
                                        <ul>
                                            {dealDetails.map((detail, index) => {
                                                const isFieldToShow = dealFieldsToShow.some(fieldPath => {
                                                    return fieldPath === detail.key || fieldPath.startsWith(detail.key + '.');
                                                });

                                                if (isFieldToShow) {
                                                    let displayValue;
                                                    if (typeof detail.value === 'object' && !Array.isArray(detail.value) && detail.value !== null) {
                                                        displayValue = Object.entries(detail.value).map(([key, value]) => {
                                                            const nestedPath = `${detail.key}.${key}`;
                                                            if (dealFieldsToShow.includes(nestedPath)) {
                                                                return <div key={nestedPath}>{`${key}: ${value}`}</div>;
                                                            }
                                                            return null;
                                                        });
                                                    } else {
                                                        displayValue = detail.value;
                                                    }

                                                    return (
                                                        <li key={index}>
                                                            <strong>{detail.key}:</strong> {displayValue}
                                                        </li>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </ul>
                                    ) : (
                                        <p>Deal not added yet</p>
                                    )}
                                    <button onClick={() => setShowDetailsPopup(false)}>Close</button>
                                </div>
                            )}
                        </div>
                        <div className="ed" id="eDate">
                            &#128197;<DatePicker id="subscriptionStartDate"
                                selected={selectedDate_I}
                                onChange={(date) => setSelectedDate_I(date)}
                                dateFormat="MM/dd/yyyy"
                                placeholderText="Subscription Date"
                            />
                        </div>

                        <div style={{ display: 'none' }}>
                            <input type="hidden" id="matchedEmployeeId" value={matchedEmployeeId} />

                        </div>
                        <br />
                        <button type="submit" className="btn btn-primary">Add Invoice</button>
                    </form>
                </Modal.Body>
            </Modal>



            <ToastContainer className="toastMessage" />
            <div className="overlay" id="dialog-container">
                <div className="popup">

                    <p className="lcrMess" id="lMess">Live Call Routing Enabled Successfully!...Page will reload automatically...</p>
                    <p className="lcrFailMess" id="lFailMess">Enabling Live Call Routing was unsuccessful!...Page will reload automatically...</p>
                </div>
            </div>
        </div>


    )

};