import React from 'react';

const EnableLCRButton = ({ onClick }) => {
  return (

      <button variant="primary" className="lcrButton" onClick={onClick}>
        Enable Live Call Routing
      </button>

  );
};

export default EnableLCRButton;