import { Redirect } from 'react-router-dom';
import { getCustomerDetails, getCSPeople, assignCs } from '../fetch';
import React, { useEffect, useState, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import { Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const cspage = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [redirectToSearchResults, setRedirectToSearchResults] = useState(false);
    const [redirectAccount, setRedirectAccount] = useState(''); // New state for redirect account
    const [customerData, setCustomerData] = useState([]);
    const [selectedCustomerSuccess, setSelectedCustomerSuccess] = useState('');
    const [availableCustomerSuccess, setAvailableCustomerSuccess] = useState([]);
    const [showModal_CS, setShowModalForCS] = useState(false);
    const [selectedClientUrl, setSelectedClientUrl] = useState(''); // New state to hold the selected client URL
    const [searchClientUrl, setSearchClientUrl] = useState(''); // New state for search term
    const [showSearchBox, setShowSearchBox] = useState(false); // State to control visibility of the search box
    const [isLoading, setIsLoading] = useState(true); // New state for loading
    const modalRef_addCS = useRef(null);
    const formRef = useRef(null);
    const { instance } = useMsal();
    const currentAccount = instance.getActiveAccount();
    var dataT;

    useEffect(() => {
        getCustDetails(); // Fetch packages when the component mounts
    }, []);

    useEffect(() => {
        fetchCSPeople();
    }, []);

    const handleShowModal_forCS = (clientUrl) => {
        setSelectedClientUrl(clientUrl); // Set the selected client URL
        if (modalRef_addCS.current) {
            modalRef_addCS.current.show = true;
            setShowModalForCS(true);
        }
    };

    const handleCloseModal_forCS = () => {
        if (modalRef_addCS.current) {
            modalRef_addCS.current.show = false;
            setShowModalForCS(false);
        }
    };

    const getCustDetails = () => {
        getCustomerDetails().then(response => {
            if (response.status === 200 || response.status === 201) {
                dataT = response.data;
                setCustomerData(dataT);
            } else {
                setCustomerData([]);
            }
            setIsLoading(false); // Set loading to false when data is fetched
        }).catch(() => {
            setCustomerData([]);
            setIsLoading(false); // Set loading to false in case of error
        });
    };

    const fetchCSPeople = () => {
        getCSPeople().then(response => {
            if (response.status === 200 || response.status === 201) {
                dataT = response.data;
                setAvailableCustomerSuccess(dataT);
            } else {
                setAvailableCustomerSuccess([]);
            }
        });
    };

    const handleCustomerSuccess = (e) => {
        setSelectedCustomerSuccess(e.target.value);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submit action
        setRedirectAccount(searchTerm); // Set the account to redirect to
        setRedirectToSearchResults(true); // Trigger redirection
    };

    const handleSearchClientUrlChange = (e) => {
        setSearchClientUrl(e.target.value);
    };

    const handleSearchIconClick = () => {
        setShowSearchBox(!showSearchBox);
    };

    const handleRowClick = (account) => {
        setRedirectAccount(account);
        setRedirectToSearchResults(true);
    };

    if (redirectToSearchResults) {
        const searchPath = `/signupdetails?account=${encodeURIComponent(redirectAccount)}`;
        return <Redirect to={searchPath} />;
    }

    const handleSubmit_assignCs = (event) => {
        event.preventDefault();

        const formd = event.target;

        const formData = {
            selected_cs: formd.customerSuccess.value

        };

        console.log(selectedClientUrl);
        console.log(formData.selected_cs);

        assignCs(selectedClientUrl, formData.selected_cs).then((response) => {
            if ((response.status === 200 || response.status === 201) && (response.data[0].product_package_id !== 0)) {
                toast.success('Customer Success Person Assigned. Page will Reload', {
                    onClose: () => {
                        window.location.reload();
                    },
                    autoClose: 3000
                });
            }
            else {

                toast.error('CS Assignment was unsuccessful. Page will Reload', {
                    onClose: () => {
                        window.location.reload();
                    },
                    autoClose: 3000
                });
            }
        });

        handleCloseModal_forCS();



    };

    const filteredCustomerData = customerData.filter(customer =>
        customer.client_url.toLowerCase().includes(searchClientUrl.toLowerCase())
    );

    return (
        <div>
            <div>
                <center>
                    <form onSubmit={handleSubmit} className="search-form">
                        <h4>Enter Customer 'account/subdomain' to see details</h4>
                        <br />
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="search-input"
                            placeholder="Enter Account..."
                            required
                        />
                        <button type="submit" className="search-button" style={{ position: 'sticky', top: 0, backgroundColor: '#b7dee5ca' }}><strong>Get Details</strong></button>
                    </form>
                </center>
                <br />
                <br />
                <br />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h3>Unassigned Customers</h3>
                <span style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={handleSearchIconClick}>
                    &#128269;
                </span>
            </div>
            {showSearchBox && (
                <center>
                    <div>
                        <input
                            type="text"
                            value={searchClientUrl}
                            onChange={handleSearchClientUrlChange}
                            className="search-input"
                            placeholder="Search Unassigned Account"
                        />
                    </div>
                </center>
            )}
            <br />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="table-responsive">
                    {isLoading ? (
                        <center><div className="loader"></div></center>
                    ) : (
                        <table className="table" style={{ width: '100%', tableLayout: 'fixed' }}>
                            <thead style={{ backgroundColor: '#eeb051' }}>
                                <tr>
                                    <th style={{ width: '33%', position: 'sticky', top: 0, backgroundColor: '#eeb051' }}>Account</th>
                                    <th style={{ width: '33%', position: 'sticky', top: 0, backgroundColor: '#eeb051' }}>Subscription Start Date</th>
                                    <th style={{ width: '33%', position: 'sticky', top: 0, backgroundColor: '#eeb051' }}>Product Package</th>
                                    <th style={{ width: '33%', position: 'sticky', top: 0, backgroundColor: '#eeb051' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {customerData.length === 0 ? (
                                    <tr>
                                        <td colSpan="4" style={{ textAlign: 'center' }}>No Unassigned Customers</td>
                                    </tr>
                                ) : (
                                    filteredCustomerData.map((customer, index) => (
                                        <tr key={index} onClick={() => handleRowClick(customer.client_url)} style={{ cursor: 'pointer' }} className="CS-clickable-row">
                                            <td data-label="Account">{customer.client_url}</td>
                                            <td data-label="Subscription Start Date">{customer.subscription_start_date || 'No start date'}</td>
                                            <td data-label="Product Package">{customer.product_package_name}</td>
                                            <td data-label="Actions">
                                                <Button
                                                    type="button"
                                                    style={{ position: 'sticky', top: 0, color:'black', backgroundColor: '#dce2e2ca' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // Stop row click event
                                                        handleShowModal_forCS(customer.client_url);
                                                    }}
                                                >
                                                    Assign CS person
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>

            <Modal ref={modalRef_addCS} show={showModal_CS} onHide={handleCloseModal_forCS} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Assign Customer Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form ref={formRef} onSubmit={handleSubmit_assignCs}>
                        <div className="mb-3">
                            <label htmlFor="text2" className="form-label"><strong>Select Customer Success Person: </strong>&nbsp;</label>
                            <select
                                id="customerSuccess"
                                className="form-select"
                                value={selectedCustomerSuccess}
                                onChange={handleCustomerSuccess}
                                required
                            >
                                <option value="">Select Customer Success</option>
                                {availableCustomerSuccess.map((pack) => (
                                    <option key={pack.employee_id} value={pack.employee_id}>
                                        {pack.employeename}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <p><strong>Selected Client URL:</strong> {selectedClientUrl}</p> {/* Display the selected client URL */}
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </Modal.Body>
            </Modal>
            <ToastContainer className="toastMessage" />
        </div>
    );
};
