import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType } from "@azure/msal-browser";

export const RouteGuard = ({ Component, ...props }) => {

    const { instance , accounts, inProgress} = useMsal();
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [progressState, setProgressState] = useState(false);
    const [rolesState, setRolesState] = useState(false);

    const onLoad = async () => {
        const currentAccount = instance.getActiveAccount();

        if (currentAccount && currentAccount.idTokenClaims['roles']) {
            let intersection = props.roles
                .filter(role => currentAccount.idTokenClaims['roles'].includes(role));
            if(intersection.length != 0){
                setRolesState(true);
            }
            
            if(inProgress === InteractionStatus.None){
                setProgressState(true);
            }
            if (intersection.length > 0 && accounts.length > 0) {
                setIsAuthorized(true);
            }
        }
    }

    useEffect(() => {
        onLoad();
    }, [instance, accounts,inProgress]);


    return (
        <>
            
            {
                (isAuthorized && progressState)
                    ?
                    <Route {...props} render={routeProps => <Component {...routeProps} />} />
                    :
                (progressState === false && accounts.length == 0)
                    ?
                    <div className="data-area-div">
                        <h3></h3>
                    </div>
                    :
                (progressState === false && inProgress === InteractionStatus.None)
                    ?
                    <div className="data-area-div">
                        <h3>Unauthorized</h3>
                    </div>
                    :
                    <div className="data-area-div">
                        <h3>You are unauthorized to view this content.</h3>
                    </div>
            }
        </>
    );
};